import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import NaturalOasis from "../../components/NaturalOasis/NaturalOasisContainer"
import SEO from "../../components/seo"

import "../../styles/Global.css"
import "../../styles/layout.module.css"

const DescubriSignUpPage = () => {

    useEffect(() => {
        window.dataLayer.push({
            brand: "Corona",
            Zone: "SAZ",
            country: "ARG",
            city: "CABA",
            campaign: `${
                window.location.search.includes("?cmp=")
                    ? window.location.search.split("?cmp=")[1].split("&")[0]
                    : ""
            }`,
            cms: "0",
            event: "pageView",
            language: "es",
            login: false,
            pageName: "Ingredientes Naturales Participar",
            pageType: "Registration",
            SiteType: "Registration",
            product: "NA",
            sku: "NA",
            userUid: "",
            url: "https://www.cervezacorona.com.ar/ingredientesnaturales/participar",
            url_campaign: `${
                window.location.search.includes("utm_campaign=")
                    ? window.location.search.split("utm_campaign=")[1]
                    : ""
            }`,
            Step_number: "",
        })
    }, [])

    return (
        <div>
            <SEO title="Corona Sunset is calling" />
            <NaturalOasis
                page={"Participar"}
            />
        </div>
    )
}

export default DescubriSignUpPage

